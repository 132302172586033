@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #fffaf2;
    --foreground: #1c1c1c;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Sarabun', sans-serif;
  font-size: 1.5048rem;
  line-height: 1.125;
}

h1 {
  font-size: 1.875rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.62rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h1,
h2 {
  font-family: 'Mate', serif;
  line-height: 1.25;
  font-weight: 700;
}
